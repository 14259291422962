@font-face {
  font-family: "Tiny";
  src: url("./Tiny/Tiny-ExtraBold.ttf") format("truetype");

  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Tiny";
  src: url("./Tiny/Tiny-Bold.ttf") format("truetype");

  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Tiny";
  src: url("./Tiny/Tiny-SemiBold.ttf") format("truetype");

  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Tiny";
  src: url("./Tiny/Tiny-Medium.ttf") format("truetype");

  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Tiny";
  src: url("./Tiny/Tiny-Regular.ttf") format("truetype");

  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Tiny";
  src: url("./Tiny/Tiny-Light.ttf") format("truetype");

  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Tiny";
  src: url("./Tiny/Tiny-ExtraLight.ttf") format("truetype");

  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Tiny";
  src: url("./Tiny/Tiny-Thin.ttf") format("truetype");

  font-weight: 100;
  font-style: normal;
}
