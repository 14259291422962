:root {
  --overlay: rgba(0, 0, 0, 0.2);

  --black: #000000;
  --black-rgb: 0, 0, 0;
  --gray-900x: #111111;
  --gray-900: #181818;
  --gray-850: #1f1f1f;
  --gray-850-rgb: 31, 31, 31;
  --gray-800: #242424;
  --gray-800-rgb: 36, 36, 36;
  --gray-700: #363636;
  --gray-600: #494949;
  --gray-500: #808080;
  --gray-500-rgb: 128, 128, 128;
  --gray-450: #959595;
  --gray-400: #cccccc;
  --gray-400-rgb: 204, 204, 204;
  --gray-300: #dfdfdf;
  --gray-200: #efefef;
  --gray-100: #f8f8f8;
  --white: #ffffff;
  --white-rgb: 255, 255, 255;

  --lime-500: #f2fe67;
  --lime-500-rgb: 242, 254, 103;
  --lime-600: #e8fb67;
  --lime-700: #ddf868;

  --pink-400: #ffe4fe;
  --pink-500: #ffdbfe;
  --pink-500-rgb: 255, 219, 254;
  --pink-700: #dcb0ff;
  --pink-700-rgb: 220, 176, 255;
  --pink-750: #cc93ff;
  --pink-750-rgb: 205, 148, 255;
  --pink-800: #a056ff;
  --pink-800-rgb: 160, 86, 255;

  --green-300: #2bea9a;
  --green-300-rgb: 43, 234, 154;
  --green-500: #10cd7e;
  --green-600: #0ebc73;
  --green-600-rgb: 14, 188, 115;

  --aqua-300: #20e2bf;
  --aqua-300-rgb: 32, 226, 191;
  --aqua-500: #00bfa8;
  --aqua-500-rgb: 0, 191, 168;
  --aqua-600: #00b29d;

  --blue-100: #9ac2f6;
  --blue-100-rgb: 154, 194, 246;
  --blue-200: #56c2ff;
  --blue-200-rgb: 86, 194, 255;
  --blue-250: #2d9aff;
  --blue-250-rgb: 45, 154, 255;
  --blue-300: #4971ff;
  --blue-300-rgb: 73, 113, 255;
  --blue-500: #2958ff;
  --blue-500-rgb: 41, 88, 255;

  --red-200: #f5a122;
  --red-300: #ff8b4a;
  --red-300-rgb: 255, 139, 74;
  --red-500: #ff603e;
  --red-500-rgb: 255, 96, 62;
  --red-700: #f54822;
  --red-700-rgb: 245, 72, 34;
}
