* {
  box-sizing: border-box;

  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

ul,
ol,
li {
  list-style-type: none;
}

a {
  text-decoration: none;

  color: var(--text-main);
}
