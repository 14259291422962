@import "../../../core/ui/style/typography/typography-mixins";

.configuration-swap-view {
  .MuiFormControlLabel-root {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 20px;

    margin-left: 2px;
  }

  .MuiFormControlLabel-label {
    @include typography--body();
  }

  .MuiToggleButtonGroup-root {
    gap: 12px;

    margin-bottom: 20px;
  }

  .MuiInputBase-root {
    border-radius: 8px;
  }

  .MuiToggleButton-root {
    width: 181px;
    height: 36px;

    margin: 0 !important;

    border-radius: 8px !important;

    text-transform: capitalize;

    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);

    &.Mui-selected {
      box-shadow: none;
    }
  }

  .MuiOutlinedInput-root {
    height: 40px;

    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
  }
}

.configuration-swap-view__service-fee-percentage-toggle-button-group {
  .MuiToggleButton-root {
    width: 72px;
  }

  .MuiToggleButtonGroup-grouped:not(:first-of-type) {
    border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
  }

  .configuration-swap-view .MuiToggleButtonGroup-root {
    margin-bottom: 0 !important;
  }

  .MuiOutlinedInput-root {
    height: 36px !important;
  }
}
