.app {
  display: grid;
  grid-template-columns: 266px 471px 1fr;
}

.app__configuration {
  height: 100vh;

  padding: 42px 48px;

  overflow: auto;
}

.app__configured-widget {
  background-color: #f8f8f8;

  border-left: 1px solid var(--border-default);
}
